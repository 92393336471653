import type { SelectedColumn } from 'o365-modules';
import type { DataItemModel, ItemModel } from 'o365-dataobject';
import type DataColumn from './DataGrid.DataColumn.ts';
import type DataGridControl from './DataGridControl.ts';
import { BaseSelectionControl } from 'o365-modules';


export default class DataSelectionControl<T extends ItemModel = ItemModel> extends BaseSelectionControl<T> {
    /** Main */
    private _data: Partial<DataItemModel>[];
    private _selectedDataItems: Set<Partial<DataItemModel>> = new Set();
    private _dataGridControl: DataGridControl;

    selectAllLoading: boolean = false;

    get selectedDataItems() {
        return this._selectedDataItems;
    }

    get selectedRows() {
        return Array.from(this._selectedDataItems) as T[];
    }

    constructor(pOptions: { data: Partial<DataItemModel>[], dataGridControl: DataGridControl }) {
        super();
        this._data = pOptions.data;
        this.checkIsSelected();
        this._dataGridControl = pOptions.dataGridControl;
        this.allRowsSelected = this.isAllRowsSelected();
    }
    updateData(data:DataItemModel[]){
        this._data = data;
    }
    checkIsSelected() {
        this._data?.forEach(item => {
            if (item.isSelected) {
                this._selectedDataItems.add(item);
            }
        })
    }

    onSelection(pRow: T) {
        if (pRow.item.isSelected) {
            this._selectedDataItems.add(pRow.item);
        } else {
            this._selectedDataItems.delete(pRow.item);
        }

        this.allRowsSelected = this.isAllRowsSelected();
    }

    isAllRowsSelected() {
        return this._selectedDataItems.size === this._data.length;
    }

    isSomeRowsSelected() {
        if (this._selectedDataItems.size === 0) { return false; }
        if (this._selectedDataItems.size < this._data.length) { return true; }
        return false;
    }

    getSelection(columnArray: DataColumn[], rowData?: T[], pOptions?: {
        valueResolve?: (pColumn: any, pRow: T) => [string, any],
    }) {
        if (this.isValidSelection) {
            const srcRows = rowData;
            if (!srcRows) { return []; }
            const rows = this.getRows(srcRows);
            const columns =  this.getColumns(columnArray);
            if (rows == null || columns == null) { return; }
            const selected: Record<string, any>[] = [];
            rows.forEach((row, index) => {
                selected[index] = {};
                selected[index].index = row.index;
                columns.forEach(col => {
                    if (pOptions?.valueResolve) {
                        const resolvedValues = pOptions.valueResolve(col, row);
                        if (Array.isArray(resolvedValues)) {
                            const [fieldName, fieldValue] = resolvedValues;
                            selected[index][fieldName] = fieldValue;
                        } else if (typeof resolvedValues == 'object') {
                            Object.entries(resolvedValues).forEach(([fieldName, fieldValue]) => {
                                if (fieldName == '$filter' && typeof selected[index].$filter == 'object' && typeof fieldValue == 'object') {
                                    selected[index][fieldName] = { ...selected[index][fieldName], ...fieldValue };
                                } else {
                                    selected[index][fieldName] = fieldValue;
                                }
                            });
                        }
                    } else {
                        selected[index][col.name] = row[col.name];
                    }
                });
            });
            return selected;
        } else {
            return [];
        }
    }

    getSelectedRowsData(columnArray: SelectedColumn[]) {
        const selectedRows = this._data?.filter((item) => item.isSelected);
        const selection: Record<string, any>[] = [];
        if (selectedRows) {
            selectedRows.forEach((row) => {
                let createdRow: Record<string, any> = {}
                columnArray.forEach((item) => {
                    const fieldName = typeof item === 'string' ? item : item.name;
                    if (!fieldName.startsWith('o365')) {
                        createdRow[fieldName] = row[fieldName];
                    }
                });
                selection.push(createdRow);
            });
        }
        return selection;
    }

    selectAll(setSelectionTo: boolean = true) {
        this.allRowsSelected = !this.allRowsSelected;
        this._data.forEach(item => {
            item.isSelected = setSelectionTo;
            this._selectedDataItems.add(item);
        })

        if (!setSelectionTo) {
            this._selectedDataItems.clear();
        }
    }

    copySelection(withHeaders: boolean = false, gridColumns: SelectedColumn[], copyAsJson = false) {
        let selectedRows: T[] | null = null;
        if (this._selectedDataItems.size > 0) {
            selectedRows = Array.from(this._selectedDataItems) as T[];
        } else {
            selectedRows = this._data.filter(item => item.isSelected) as T[];
        }
        if (selectedRows == null) {
            return;
        }
        const columns = this.getColumnsForCopy(selectedRows, gridColumns);
        const rows = selectedRows.length ? selectedRows : this.getRows(this._data);
        if (columns == null || rows == null) { return; }
        let result = '';
        const jsonCopy: Record<string, any>[] = [];
        if (columns && columns.length > 0 && withHeaders) {
            columns.forEach((col, colIndex) => {
                result += col.caption;
                if (colIndex < columns.length - 1) { result += '\t'; }
            })
            result += '\n';
        }
        rows.forEach((row, rowIndex) => {
            let jsonColumnData: Record<string, any> = {};
            columns.forEach((col, colIndex) => {
                if (col.unbound) {
                    if (col.getCopyValue) {
                        result += col.getCopyValue(row, 0) ?? '\t';
                        jsonColumnData[col.name] = col.getCopyValue(row, 0);
                    }
                } else {
                    const value = row[col.name];
                    if (value != null) {
                        let formatedValue = this.formatForCopyValue(value, col.type);;
                        jsonColumnData[col.name] = formatedValue;
                        result += formatedValue;
                    }
                }
                if (colIndex < columns.length - 1) { result += '\t'; }
            });

            jsonCopy.push(jsonColumnData);
            if (rowIndex < rows.length - 1) { result += '\n'; }
        });
        result = copyAsJson ? JSON.stringify(jsonCopy) : result;
        navigator.clipboard.writeText(result)
            .then(() => console.log("Success copy", result))
            .catch((error) => console.error("error", error));
    }

    handleJsonPaste(text: string, columns: DataColumn[]) {
        return false;
    }

    async pasteSelection(event: KeyboardEvent, columns: DataColumn[]) {
        try {
            this._isPasting = true;
            let text = '';
            if (window.navigator.clipboard.read == null) {
                // Firefox
                text = await window.navigator.clipboard.readText(); // Seems like this is only supported in extensions for now, but nightly supports it on pages too
                // Need a temp alternative for firefox
            } else {
                // WebKit
                const data = await window.navigator.clipboard.read();
                if (data[0] == null) { return; }
                if (data[0].types.includes('web text/json')) {
                    // Clipboard contains data in json, use that instead of plain text
                    const blob = await data[0].getType('web text/json')
                    text = await blob.text();
                } else if (data[0].types.includes('text/plain')) {
                    const blob = await data[0].getType('text/plain')
                    text = await blob.text();
                } else {
                    return;
                }
            }

            if (this.handleJsonPaste(text, columns)) {
                // JSON paste successful, skip default paste
                return;
            }

            const splitRows = text.split(/\r/);
            const rows = splitRows.filter((row, index) => {
                if (index < (splitRows.length - 1) && row == '\n') {
                    return true;
                } else if (index === (splitRows.length - 1) && row == '\n') {
                    return false;
                } else {
                    return true;
                }
            })
            const parsedText = rows.map(row => row.split('\t'));

            const pasteSelection = this.determinePasteSelection(event);
          
            const selectedRows = this.getRows(this._getData(), "G");
            const selectedColumns = this.getColumns(columns);
            if (selectedRows == null || selectedColumns == null) { return; }
            this.gridDataPaste(parsedText, columns, selectedRows, selectedColumns);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            this._isPasting = false;
        }
    }

    selectRange(pValue: boolean, pStart: number, pEnd: number) {
        if (pStart > pEnd) { throw new TypeError('End cannot be greater than start in selection range'); }
        this._data.slice(pStart, pEnd + 1).forEach(item => {
            item.isSelected = pValue;
        });

        this.allRowsSelected = this.isAllRowsSelected();
    }

    public gridDataPaste(parsedText: string[][], columns: DataColumn[], selectedRows: DataItemModel<T>[], selectedColumns: DataColumn[]) {
        let parsedColumnLength: number | undefined = undefined;
        parsedColumnLength = this.parsedColumnLength(parsedText);
        if (parsedText.length === selectedRows.length && (parsedColumnLength && (parsedColumnLength === selectedColumns.length))) {
            // Exact area size match   
            const sel = this.getSelectionRange();
            if (selectedRows.length == 1 && selectedColumns.length == 1 && selectedColumns[0].editable && !selectedColumns[0].cellRenderSlot && selectedColumns[0].type == "string" && sel && sel.diff) {
                const col = selectedColumns[0];
                const row = selectedRows[0]; 
                const text = parsedText[0][0];
                let vValue = row[col.name];
                vValue = vValue.substring(0, sel.start) + text + vValue.substring(sel.end, vValue.length);
                this.applyValue(row, col, vValue);
                return;
            }
            selectedRows.forEach((row, rowIndex) => {
                selectedColumns.forEach((col, colIndex) => {
                    if (col.editable) {
                        this.applyValue(row, col, parsedText[rowIndex][colIndex]);
                    }
                });
            });
        } else if (parsedText.length >= selectedRows.length && (parsedColumnLength && (parsedColumnLength > selectedColumns.length))) {
            //  Paste area is larger in columns
            this.modifyAreaSelection(parsedColumnLength, parsedText.length, true, selectedColumns.length, selectedRows.length);

            const updatedSelectedRows = this.getRows(this._getData(), "G")!;
            const updatedSelectedColumns = this.getColumns(columns)!;

            updatedSelectedRows.forEach((row, rowIndex) => {
                if (parsedText[rowIndex] == undefined) { return; }
                updatedSelectedColumns.forEach((col, colIndex) => {
                    const pastedValue = parsedText[rowIndex][colIndex];
                    if (pastedValue == null) { return; }
                    this.applyValue(row, col, parsedText[rowIndex][colIndex]);
                });
            })
        } else if (parsedText.length <= selectedRows.length && parsedText.length > 1 && (parsedColumnLength && (parsedColumnLength <= selectedColumns.length))) {
            // Paste area is smaller than selection area, but contains at least 2 rows
            selectedRows.forEach((row, rowIndex) => {
                if (parsedText[rowIndex] == undefined) { return };
                selectedColumns.forEach((col, colIndex) => {
                    if (colIndex >= parsedColumnLength) { return; }
                    this.applyValue(row, col, parsedText[rowIndex][colIndex]);
                });
            });
            this.modifyAreaSelection(parsedColumnLength, parsedText.length, false);
        } else if (parsedColumnLength != null) {
            // Apply on single cell
             this.applyValue(selectedRows[0], selectedColumns[0], parsedText[0][0]);
            this.modifyAreaSelection(1, 1, false);
            // selectedRows.forEach((row, rowIndex) => {
            //     selectedColumns.forEach((col, colIndex) => {
            //         if (this.isEditable(col, row) || col.onPaste) {
            //             const repeatingRow = rowIndex - Math.floor(rowIndex / parsedText.length) * parsedText.length;
            //             const repeatingCol = colIndex - Math.floor(colIndex / parsedColumnLength!) * parsedColumnLength!;
            //             this.applyValue(row, col, parsedText[repeatingRow][repeatingCol]);
            //         }
            //     });
            // });
        }
    }

    private getSelectionRange() {
        const sel = window.getSelection();
        if (!sel) return null;
        const range = sel.getRangeAt(0).cloneRange();
        return {
            start: range.startOffset,
            end: range.endOffset,
            diff: range.endOffset - range.startOffset
        }
    }

    private _getData() {
        return this._dataGridControl?.utils?.processedData ?? this._dataGridControl.props.data ?? [];
    }
}